  .medical_website {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
  }


  /* Header top section */
  .medical_website .top_section {
    width: 100%;
    padding: 20px 50px 20px 50px;
  }

  .medical_website .top_section .logo_nd_name {
    display: flex;
    align-items: center;
  }

  .medical_website .top_section .logo_nd_name .circle_logo {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #874daf;
  }

  .medical_website .top_section .logo_nd_name .circle_logo .image_logo {
    width: 30px;
    height: 30px;
    filter: brightness(5) saturate(0);
  }

  .medical_website .top_section .logo_nd_name h4 {
    margin-left: 10px;
    font-weight: 500;
  }

  .medical_website .top_section h3 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 17px;
    margin-left: 5px;
    margin-bottom: 25px;
  }


  .medical_website .top_section .search_section {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px 5px 0px;
  }

  .medical_website .top_section .search_section .both_join {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85vh;
  }

  .medical_website .top_section .search_section .both_join .pahli_input {
    width: 40%;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d9d9d9;
    border-color: gray;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: white;
    padding: 0px 5px 0px 10px;
  }

  .medical_website .top_section .search_section .both_join .pahli_input .location_inpu {
    border: none;
    height: 30px;
    width: 100%;
    margin-left: 10px;
    font-size: 14px;
    color: black;
  }

  .medical_website .top_section .search_section .both_join .dusri_inpu {
    /* width: 60%; */
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9; */
    /* border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; */
    border: 1px solid #d9d9d9;
    border-radius: 8px;

    border-color: gray;
    background-color: white;
    padding: 0px 0px 0px 10px;
  }

  .medical_website .top_section .search_section .both_join .dusri_inpu .search_inpu {
    border: none;
    height: 30px;
    width: 88%;
    margin-left: 10px;
    font-size: 14px;
    color: black;
  }

  .medical_website .top_section .search_section .both_join .dusri_inpu .searching_hol {
    width: 12%;
    height: 100%;
    background-color: #f2fafe;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* /////////////////////////// */



  /* Pending Appointment */
  .medical_website .allpendingappointm {
    width: 91%;
    border-radius: 10px;
    /* padding: 15px 0px 15px 0px; */
    overflow: auto;
    margin-top: 10px;
  }

  .medical_website .allpendingappointm h3 {
    color: #014e78;
  }

  .medical_website .allpendingappointm .maindivpendingappo {
    display: flex;
    align-items: center;
    overflow: auto;
    gap: 10px;
  }

  .medical_website .allpendingappointm .maindivpendingappo::-webkit-scrollbar {
    width: 12px;
    height: 4px;
  }

  .medical_website .allpendingappointm .maindivpendingappo::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .medical_website .allpendingappointm .maindivpendingappo::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
  }


  .medical_website .allpendingappointm .maindivpendingappo .pending_appoi {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 15px 10px 15px;
    gap: 10px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .pending_appoi .skeltofpendngupcm {
    width: 55vh;
    height: 183px;
    border-radius: 12px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .nopenrecorddiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .nopenrecorddiv h4 {
    color: black;
    align-self: center;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain {
    width: 55vh;
    border-radius: 12px;
    background-color: white;
    margin: 5px 5px 5px 5px;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
    padding-top: 3px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .upcomimge {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: contain;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde {
    margin-left: 10px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .poftext {
    color: white;
    font-weight: bold;
    font-size: 14px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .poftext .spanoftet {
    color: #00c480;
    font-weight: bold;
    font-size: 10px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .clicoronlne {
    color: lightgray;
    font-size: 10px;
    text-decoration: underline;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .feofupcom {
    color: white;
    margin-top: -10px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .datofupcome {
    color: white;
    font-size: 11px;
    margin-top: 22px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 5px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .timeicon {
    color: white;
    font-size: 14px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .timeoftextin {
    color: white;
    font-size: 11px;
    margin-left: 5px;
  }

  .medical_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .statusof {
    color: lightgray;
    font-size: 10px;
    margin-left: 5px;
  }

  /* ................................. */


  /* New Header Section */
  .medical_website .howhelpdesgin {
    align-self: flex-start;
    padding: 40px 0px 0px 40px;
  }

  .medical_website .headr_second {
    width: 93%;
    background-color: white;
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: 15px 0px 15px 0px;
    gap: 12px;
  }

  .medical_website .headr_second .video_consultation {
    background-color: #e8f6f9;
    width: 20%;
    height: 270px;
    border-radius: 15px;
    padding-top: 12px;
    padding-left: 12px;
  }

  .medical_website .headr_second .video_consultation h4 {
    color: #384e78;
  }

  .medical_website .headr_second .video_consultation h5 {
    color: gray;
    font-weight: 600;
  }

  .medical_website .headr_second .video_consultation .image_consult {
    height: 100%;
    width: 100%;
    display: flex;
    align-self: center;
  }


  .medical_website .headr_second .onlyurgentsingle {
    background-color: #fbe0d0;
    width: 25%;
    height: 270px;
    border-radius: 15px;
    padding-top: 12px;
    padding-left: 12px;
  }

  .medical_website .headr_second .onlyurgentsingle h4 {
    color: #384e78;
  }

  .medical_website .headr_second .onlyurgentsingle h5 {
    color: gray;
    font-weight: 600;
  }

  .medical_website .headr_second .onlyurgentsingle .image_consult {
    height: 100%;
    width: 60%;
    object-fit: contain;
    display: flex;
    align-self: flex-end;
  }

  .medical_website .headr_second .next_two_btn {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 270px;
    justify-content: space-between;
  }

  .medical_website .headr_second .next_two_btn .book_appointm_btn {
    width: 100%;
    height: 47%;
    background-color: #fbe0d0;
    border-radius: 15px;
    /* box-shadow: 0px 2px 10px #c5c5c5; */
  }

  .medical_website .headr_second .next_two_btn .book_appointm_btn h4 {
    color: #384e78;
  }

  .medical_website .headr_second .next_two_btn .book_appointm_btn h5 {
    color: gray;
    font-weight: 600;
  }

  .medical_website .headr_second .next_two_btn .book_appointm_btn .image_book {
    width: 40%;
    height: 100%;
  }


  .medical_website .headr_second .next_two_btn .free_consult_btn {
    width: 100%;
    height: 47%;
    background-color: #fef5de;
    border-radius: 15px;
    /* box-shadow: 0px 2px 10px #c5c5c5; */
  }

  .medical_website .headr_second .next_two_btn .free_consult_btn h4 {
    color: #384e78;
  }

  .medical_website .headr_second .next_two_btn .free_consult_btn h5 {
    color: gray;
    font-weight: 600;
  }

  .medical_website .headr_second .next_two_btn .free_consult_btn .image_free {
    width: 30%;
    height: 100%;
    object-fit: contain;
  }


  .medical_website .headr_second .last_two_btn {
    display: flex;
    flex-direction: column;
    width: 52%;
    height: 270px;
    justify-content: space-between;
  }

  .medical_website .headr_second .last_two_btn .instant_btn {
    width: 100%;
    height: 47%;
    background-color: #dfefeb;
    border-radius: 15px;
  }

  .medical_website .headr_second .last_two_btn .instant_btn h3 {
    color: #384e78;
  }

  .medical_website .headr_second .last_two_btn .instant_btn h5 {
    color: gray;
    font-weight: 600;
  }

  .medical_website .headr_second .last_two_btn .instant_btn .houstonisd {
    width: 70px;
    height: 70px;
    margin-left: 90px;
  }

  .medical_website .headr_second .last_two_btn .instant_btn .image_instan {
    width: 35%;
    height: 100%;
    margin-right: 15px;
  }


  .medical_website .headr_second .last_two_btn .free_instant_btn {
    width: 100%;
    height: 47%;
    background-color: #f2fafe;
    border-radius: 15px;
    /* box-shadow: 0px 2px 10px #c5c5c5; */
  }

  .medical_website .headr_second .last_two_btn .free_instant_btn h4 {
    color: #384e78;
  }

  .medical_website .headr_second .last_two_btn .free_instant_btn h5 {
    color: gray;
    font-weight: 600;
  }

  .medical_website .headr_second .last_two_btn .free_instant_btn .image_fre_instn {
    width: 20%;
    height: 100%;
    object-fit: contain;
  }

  /* //////////////////////////// */




  /* New Sponsoor Doctor Sections */
  .medical_website .new_doc_section {
    width: 91%;
    background-color: #e8f6f9;
    border-radius: 10px;
    padding: 15px 0px 15px 0px;
    overflow: auto;
    margin-top: 10px;
  }

  .medical_website .new_doc_section h3 {
    color: #014e78;
    margin-left: 15px;
  }

  .medical_website .new_doc_section .all_doc_card {
    display: flex;
    align-items: center;
    margin: 15px 15px 0px 15px;
    overflow: auto;
    gap: 10px;
  }

  .medical_website .new_doc_section .all_doc_card .singleskelsame {
    width: 355px;
    height: 110px;
  }

  .medical_website .new_doc_section .all_doc_card::-webkit-scrollbar {
    width: 12px;
    height: 4px;
  }

  .medical_website .new_doc_section .all_doc_card::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .medical_website .new_doc_section .all_doc_card::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
  }

  .medical_website .new_doc_section .all_doc_card .one_docctor {
    width: 365px;
    border-radius: 9px;
    /* background-color: #2f7f9e; */
    background-image: linear-gradient(to right, #035077, #4fa1b9);
    margin-bottom: 10px;
  }

  .medical_website .new_doc_section .all_doc_card .one_docctor .img_nd_name {
    display: flex;
    width: 100%;
    padding: 7px 7px 0px 7px;
  }

  .medical_website .new_doc_section .all_doc_card .one_docctor .onedocimagemain {
    width: 65px;
    height: 65px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .medical_website .new_doc_section .all_doc_card .one_docctor .img_nd_name .image_doctors {
    width: 63px;
    height: 63px;
    border-radius: 100px;
    object-fit: cover;
  }

  .medical_website .new_doc_section .all_doc_card .one_docctor .img_nd_name h4 {
    color: white;
    font-weight: 500;
  }

  .medical_website .new_doc_section .all_doc_card .one_docctor .img_nd_name h5 {
    color: white;
    margin-top: 10px;
    font-weight: 400;
    font-size: 15px;
  }

  .medical_website .new_doc_section .all_doc_card .one_docctor .img_nd_name h6 {
    color: white;
    font-weight: 400;
    font-size: 15px;
  }

  .medical_website .new_doc_section .all_doc_card .one_docctor .reviw_nd_fee {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
    padding: 0px 7px 7px 7px;
  }

  .medical_website .new_doc_section .all_doc_card .one_docctor .reviw_nd_fee h4 {
    color: white;
    margin-left: 3px;
  }

  /* //////////////////////////////// */





  /* Categories Section */
  .categories {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 50px 40px 50px;
  }

  .categories .home_category_skelton_div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }

  .categories .home_category_skelton_div .categy_single_skeltn {
    background-color: white;
    height: 75px;
    width: 75px;
    border-radius: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .categories .home_category_skelton_div .categy_text_single_skeltn {
    width: 75px;
    height: 10px;
  }

  .categories h4 {
    margin-bottom: 20px;
  }

  .category_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    width: 100%;
    gap: 20px;
    padding-bottom: 3px;
  }

  .category_container::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .category_container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .category_container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
  }

  .category_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .category_item .Icon {
    color: #0069d9;
    font-size: 50px;
  }

  .category_item i {
    color: #0069d9;
    font-size: 40px;
  }

  .category_item .imageKo_circlw {
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    height: 75px;
    width: 75px;
    border-radius: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
  }


  .category_item img {
    width: 75%;
    height: 75%;
    object-fit: cover;
  }

  .category_item h3 {
    font-size: 10px;
    font-weight: bold;
  }

  /* //////////////////// */






  /* Doctor Card */
  .doc_text_sect {
    width: 100%;
    background-color: #ffffff;
    padding: 70px 45px 0px 45px;
  }

  .home_doctor_skelton_div {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0px 70px;
    border-radius: 4px;
    gap: 15px;
    justify-content: flex-start;
    padding: 20px 50px 0px 50px;
    overflow: auto;
  }

  .home_doctor_skelton_div::-webkit-scrollbar {
    width: 12px;
    height: 0px;
  }

  .home_doctor_skelton_div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .home_doctor_skelton_div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
  }

  .home_doctor_skelton_div .singleskeltfdc {
    width: 275px;
    height: 110px;
  }

  .medical_website .topdivhead {
    display: flex;
    width: 91%;
    gap: 15px;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 10px;
    overflow: auto;
  }

  .medical_website .topdivhead::-webkit-scrollbar {
    height: 1px;
  }

  .medical_website .topdivhead::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .medical_website .topdivhead::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
  }

  .doctor-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    transition: all 0.2s ease-in-out;
    width: 275px;
    justify-content: flex-start;
  }

  .doctor-card:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }

  .doctor-card-image-container {
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    height: 100%;
    width: 100px;
    aspect-ratio: 1.5;
  }

  .doctor-card-image {
    max-width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .doctor-card-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }

  .doctor-card-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }

  .doctor-card-specialty {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }

  /*  */



  /* Services Section */
  .services-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 10px auto;
  }

  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    max-width: 300px;
    text-align: center;
  }

  .service i {
    font-size: 3rem;
    margin-bottom: 10px;
  }

  .service h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .service p {
    font-size: 1rem;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .services-section {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 20px auto;
    }

    .service {
      max-width: 100%;
      margin: 20px 0;
    }

    .service i {
      font-size: 2rem;
    }

    .service h2 {
      font-size: 1.2rem;
    }

    .service p {
      font-size: 0.9rem;
    }
  }

  /*  */


  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }



  /* Play store mobile app link */
  .medical_website .playstore_appsign {
    width: 91%;
    height: 250px;
    background-color: #e8f6f9;
    border-radius: 10px;
    padding: 15px 150px 15px 150px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .medical_website .playstore_appsign .image_playstore {
    width: 30%;
    height: 100%;
    object-fit: contain;
  }

  .medical_website .playstore_appsign .name_ty_logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .medical_website .playstore_appsign .name_ty_logo .circule_for_logo {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #874daf;
  }

  .medical_website .playstore_appsign .name_ty_logo .circule_for_logo .imf_log {
    width: 25px;
    height: 25px;
    filter: brightness(5) saturate(0);
  }

  .medical_website .playstore_appsign .name_ty_logo h4 {
    color: #874daf;
    font-weight: bold;
    margin-left: 5px;
  }

  .medical_website .playstore_appsign .twoo_three_divs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    margin-left: 10px;
  }

  .medical_website .playstore_appsign .twoo_three_divs h5 {
    color: #014e78;
    margin-left: 12px;
  }

  .medical_website .playstore_appsign .two_btn_goplay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 20px;
  }

  .medical_website .playstore_appsign .two_btn_goplay .PlayStore {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    border-radius: 5px;
    height: 40px;
    width: 130px;
    padding: 5px 5px 5px 5px;
  }

  .medical_website .playstore_appsign .two_btn_goplay .PlayStore .playstore_img {
    width: 20px;
    height: 20px;
  }

  .medical_website .playstore_appsign .two_btn_goplay .PlayStore h6 {
    color: white;
    font-size: 8px;
    font-weight: 400;
  }

  .medical_website .playstore_appsign .two_btn_goplay .PlayStore h4 {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-top: -3px;
  }

  /* ................................  */



  /* Footer Section */
  .footer_top {
    width: 100%;
    margin-top: 10px;
    background-color: #1b71a1;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer_second_end {
    width: 100%;
    height: 400px;
    background-color: #1b71a1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 90px 0px 75px;
  }

  .footer_second_end .p_app_name {
    color: white;
    font-size: 45px;
  }

  .footer_second_end .text_light {
    color: white;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 300;
  }

  .footer_second_end .footor_inside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    text-decoration: none;
  }

  .footer_second_end .footor_inside .Departments {
    color: white;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .footer_second_end .footor_inside ul {
    text-decoration: none;
  }

  .footer_second_end .footor_inside ul li {
    padding: 10px 0px 10px 0px;
    list-style: none;
  }

  .footer_second_end .footor_inside ul li a {
    color: lightgray;
    text-decoration: none;
  }


  .footer_end {
    width: 100%;
    background-color: #01334e;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer_end p {
    color: white;
  }

  .footer_end p a {
    color: #1688b0;
  }

  /* //////////////////////////////////// */



  .medical_website .scroll_top_button {
    background-color: #874daf;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 2;
  }




  /* Bottom sheet */
  .calling_sheet_content {
    background-color: white;
    width: 45%;
    padding: 30px;
    border-radius: 40px 40px 0 0;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .calling_sheet_content h2 {
    color: red;
  }

  .calling_sheet_content .line {
    height: 1px;
    width: 100%;
    background-color: lightgray;
    border: none;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .calling_sheet_content p {
    color: gray;
    font-weight: bold;
    max-width: 70%;
    text-align: center;
  }

  .calling_sheet_content .twobtn_cancel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 55%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .calling_sheet_content .twobtn_cancel .close {
    background-color: #eef3ff;
    color: #874DAF;
    border-radius: 100px;
    border: none;
    width: 47%;
    padding: 13px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .calling_sheet_content .twobtn_cancel .yescancel {
    background-color: #874daf;
    color: white;
    border-radius: 100px;
    border: none;
    width: 47%;
    padding: 13px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* //////////// */







  /* For Responsive Screen */
  @media only screen and (min-width: 0px) and (max-width: 431px) {
    .medical_website .top_section {
      width: 100%;
      padding: 15px 10px 15px 10px;
    }

    .medical_website .howhelpdesgin {
      align-self: flex-start;
      padding: 5px 0px 0px 10px;
    }

    .medical_website .headr_second .video_consultation {
      width: 45%;
      height: 200px;
      border-radius: 10px;
      padding-top: 10px;
      padding-left: 5px;
    }

    .medical_website .headr_second .video_consultation h4 {
      font-size: 12px;
    }

    .medical_website .headr_second .video_consultation h5 {
      font-size: 12px;
      font-weight: 400;
    }

    .medical_website .headr_second .video_consultation .image_consult {
      height: 90%;
      width: 100%;
    }

    .medical_website .headr_second .onlyurgentsingle {
      width: 51%;
      height: 200px;
      border-radius: 10px;
      padding-top: 10px;
      padding-left: 5px;
    }

    .medical_website .headr_second .onlyurgentsingle h4 {
      font-size: 12px;
    }

    .medical_website .headr_second .onlyurgentsingle h5 {
      font-size: 12px;
      font-weight: 400;
    }

    .medical_website .headr_second .onlyurgentsingle .image_consult {
      height: 95%;
    }

    .medical_website .headr_second .next_two_btn {
      width: 57%;
      height: 200px;
    }

    .medical_website .headr_second .next_two_btn .book_appointm_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .next_two_btn .book_appointm_btn h4 {
      font-size: 12px;
    }

    .medical_website .headr_second .next_two_btn .book_appointm_btn h5 {
      font-size: 12px;
      font-weight: 400;
    }

    .medical_website .headr_second .next_two_btn .free_consult_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .next_two_btn .free_consult_btn h4 {
      font-size: 12px;
    }

    .medical_website .headr_second .next_two_btn .free_consult_btn h5 {
      font-size: 12px;
      font-weight: 400;
    }

    .medical_website .headr_second .last_two_btn {
      width: 100%;
      height: 250px;
    }

    .medical_website .headr_second .last_two_btn .instant_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .last_two_btn .instant_btn h3 {
      font-size: 13px;
    }

    .medical_website .headr_second .last_two_btn .instant_btn h5 {
      font-size: 12px;
      font-weight: 400;
    }

    .medical_website .headr_second .last_two_btn .instant_btn .houstonisd {
      margin-left: 65px;
    }

    .medical_website .headr_second .last_two_btn .free_instant_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .last_two_btn .free_instant_btn h4 {
      font-size: 13px;
    }

    .medical_website .headr_second .last_two_btn .free_instant_btn h5 {
      font-size: 12px;
      font-weight: 400;
    }


    .categories {
      padding: 15px 20px 15px 20px;
    }

    .categories h4 {
      margin-bottom: 10px;
    }

    .category_container::-webkit-scrollbar {
      height: 7px;
    }

    .category_item .imageKo_circlw {
      height: 60px;
      width: 60px;
    }

    .category_item img {
      width: 60%;
      height: 60%;
    }

    .category_item h3 {
      font-size: 8px;
    }


    .medical_website .new_doc_section h3 {
      font-size: 14px;
    }

    .medical_website .new_doc_section .all_doc_card {
      margin: 5px 15px 0px 15px;
    }

    .medical_website .new_doc_section .all_doc_card .singleskelsame {
      width: 250px;
      height: 100px;
    }

    .medical_website .new_doc_section .all_doc_card .one_docctor {
      width: 260px;
      margin-bottom: 5px;
    }

    .medical_website .new_doc_section .all_doc_card .one_docctor .img_nd_name {
      padding: 5px 5px 0px 5px;
    }

    .medical_website .new_doc_section .all_doc_card .one_docctor .onedocimagemain {
      width: 63px;
      height: 63px;
    }

    .medical_website .new_doc_section .all_doc_card .one_docctor .img_nd_name .image_doctors {
      width: 61px;
      height: 61px;
    }

    .medical_website .new_doc_section .all_doc_card .one_docctor .img_nd_name h4 {
      font-size: 15px;
    }

    .medical_website .new_doc_section .all_doc_card .one_docctor .img_nd_name h5 {
      margin-top: 7px;
      font-size: 13px;
    }

    .medical_website .new_doc_section .all_doc_card .one_docctor .img_nd_name h6 {
      font-size: 13px;
    }

    .medical_website .new_doc_section .all_doc_card .one_docctor .reviw_nd_fee {
      padding: 0px 5px 5px 5px;
    }

    .medical_website .new_doc_section .all_doc_card .one_docctor .reviw_nd_fee h4 {
      font-size: 14px;
    }

    .doc_text_sect {
      padding: 20px 20px 0px 20px;
    }

    .home_doctor_skelton_div {
      border-radius: 4px;
      gap: 15px;
      margin: 0px 0px;
      padding: 10px 30px 0px 30px;
    }

    .home_doctor_skelton_div .singleskeltfdc {
      width: 160px;
      height: 155px;
      border-radius: 10px;
    }

    .doctor-card {
      width: calc(50% - 20px);
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .doctor-card:hover {
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
      transform: translateY(0);
    }

    .doctor-card-image-container {
      border-radius: 10px;
      overflow: hidden;
      height: 130px;
      width: 100%;
      aspect-ratio: 3/2;
    }

    .doctor-card-details {
      padding: 10px;
      text-align: center;
    }

    .doctor-card-name,
    .doctor-card-specialty {
      font-size: 16px;
      margin-bottom: 5px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }


    .medical_website .playstore_appsign {
      height: 200px;
      padding: 10px 10px 10px 10px;
    }

    .medical_website .playstore_appsign .image_playstore {
      width: 40%;
    }

    .medical_website .playstore_appsign .name_ty_logo .circule_for_logo {
      width: 20px;
      height: 20px;
    }

    .medical_website .playstore_appsign .name_ty_logo .circule_for_logo .imf_log {
      width: 13px;
      height: 13px;
    }

    .medical_website .playstore_appsign .name_ty_logo h4 {
      font-size: 13px;
    }


    .medical_website .playstore_appsign .twoo_three_divs h5 {
      font-size: 10px;
    }

    .medical_website .playstore_appsign .two_btn_goplay {
      gap: 5px;
    }

    .medical_website .playstore_appsign .two_btn_goplay .PlayStore {
      height: 35px;
      width: 110px;
      padding: 3px;
    }

    .medical_website .playstore_appsign .two_btn_goplay .PlayStore .playstore_img {
      width: 15px;
      height: 15px;
    }

    .medical_website .playstore_appsign .two_btn_goplay .PlayStore h4 {
      font-size: 13px;
    }

    .medical_website .scroll_top_button {
      padding: 10px 18px;
      bottom: 10px;
      right: 15px;
    }
  }


  /* Mobile styles */
  @media only screen and (min-width: 431px) and (max-width: 680px) {
    .medical_website .top_section {
      width: 100%;
      padding: 20px;
    }

    .medical_website .howhelpdesgin {
      align-self: flex-start;
      padding: 10px 0px 0px 20px;
    }

    .medical_website .headr_second .video_consultation {
      width: 45%;
      height: 250px;
    }

    .medical_website .headr_second .video_consultation h4 {
      font-size: 12px;
    }

    .medical_website .headr_second .video_consultation h5 {
      font-size: 12px;
      font-weight: 400;
    }

    .medical_website .headr_second .onlyurgentsingle {
      width: 52%;
      height: 250px;
    }

    .medical_website .headr_second .onlyurgentsingle h4 {
      font-size: 12px;
    }

    .medical_website .headr_second .onlyurgentsingle h5 {
      font-size: 12px;
      font-weight: 400;
    }

    .medical_website .headr_second .next_two_btn {
      width: 57%;
      height: 250px;
    }

    .medical_website .headr_second .next_two_btn .book_appointm_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .next_two_btn .book_appointm_btn h4 {
      font-size: 12px;
    }

    .medical_website .headr_second .next_two_btn .book_appointm_btn h5 {
      font-size: 12px;
      font-weight: 400;
    }

    .medical_website .headr_second .next_two_btn .free_consult_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .next_two_btn .free_consult_btn h4 {
      font-size: 12px;
    }

    .medical_website .headr_second .next_two_btn .free_consult_btn h5 {
      font-size: 12px;
      font-weight: 400;
    }

    .medical_website .headr_second .last_two_btn {
      width: 100%;
      height: 250px;
    }

    .medical_website .headr_second .last_two_btn .instant_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .last_two_btn .instant_btn h3 {
      font-size: 13px;
    }

    .medical_website .headr_second .last_two_btn .instant_btn h5 {
      font-size: 12px;
      font-weight: 400;
    }

    .medical_website .headr_second .last_two_btn .instant_btn .houstonisd {
      margin-left: 110px;
    }

    .medical_website .headr_second .last_two_btn .free_instant_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .last_two_btn .free_instant_btn h4 {
      font-size: 13px;
    }

    .medical_website .headr_second .last_two_btn .free_instant_btn h5 {
      font-size: 12px;
      font-weight: 400;
    }


    .categories {
      padding: 20px 25px 20px 25px;
    }

    .categories h4 {
      margin-bottom: 10px;
    }

    .category_container::-webkit-scrollbar {
      height: 10px;
    }

    .category_item .imageKo_circlw {
      height: 68px;
      width: 68px;
    }

    .category_item img {
      width: 68%;
      height: 68%;
    }

    .medical_website .new_doc_section h3 {
      font-size: 15px;
    }

    .medical_website .new_doc_section .all_doc_card {
      margin: 10px 15px 0px 15px;
    }

    .medical_website .new_doc_section .all_doc_card .singleskelsame {
      width: 280px;
      height: 100px;
    }

    .medical_website .new_doc_section .all_doc_card .one_docctor {
      width: 310px;
      margin-bottom: 5px;
    }


    .doc_text_sect {
      padding: 30px 30px 0px 30px;
    }

    .home_doctor_skelton_div {
      margin: 0px 0px;
      padding: 15px 40px 0px 40px;
    }

    .doctor-card {
      width: calc(50% - 20px);
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .doctor-card:hover {
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
      transform: translateY(0);
    }

    .doctor-card-image-container {
      border-radius: 10px;
      overflow: hidden;
      height: 150px;
      width: 100%;
      aspect-ratio: 3/2;
    }

    .doctor-card-details {
      padding: 10px;
      text-align: center;
    }

    .doctor-card-name,
    .doctor-card-specialty {
      font-size: 16px;
      margin-bottom: 5px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .medical_website .playstore_appsign {
      height: 230px;
      padding: 15px 40px 15px 40px;
    }

    .medical_website .playstore_appsign .image_playstore {
      width: 50%;
    }

    .medical_website .playstore_appsign .name_ty_logo .circule_for_logo {
      width: 30px;
      height: 30px;
    }

    .medical_website .playstore_appsign .name_ty_logo .circule_for_logo .imf_log {
      width: 20px;
      height: 20px;
    }

    .medical_website .playstore_appsign .name_ty_logo h4 {
      font-size: 14px;
    }


    .medical_website .playstore_appsign .twoo_three_divs h5 {
      font-size: 11px;
    }

    .medical_website .playstore_appsign .two_btn_goplay .PlayStore {
      height: 40px;
      width: 120px;
      padding: 4px;
    }

    .medical_website .playstore_appsign .two_btn_goplay .PlayStore .playstore_img {
      width: 17px;
      height: 17px;
    }

    .medical_website .playstore_appsign .two_btn_goplay .PlayStore h4 {
      font-size: 14px;
    }

    .medical_website .scroll_top_button {
      padding: 10px 20px;
      bottom: 10px;
      right: 20px;
    }
  }




  /* Tablet styles */
  @media only screen and (min-width: 680px) and (max-width: 950px) {
    .medical_website .top_section {
      width: 100%;
      padding: 20px 30px 20px 30px;
    }

    .medical_website .howhelpdesgin {
      align-self: flex-start;
      padding: 30px 0px 0px 20px;
    }

    .medical_website .headr_second .video_consultation {
      width: 24%;
      height: 240px;
    }

    .medical_website .headr_second .video_consultation h4 {
      font-size: 11px;
    }

    .medical_website .headr_second .video_consultation h5 {
      font-size: 12px;
      font-weight: 600;
    }

    .medical_website .headr_second .onlyurgentsingle {
      width: 25%;
      height: 240px;
    }

    .medical_website .headr_second .onlyurgentsingle h4 {
      font-size: 11px;
    }

    .medical_website .headr_second .onlyurgentsingle h5 {
      font-size: 12px;
      font-weight: 600;
    }


    .medical_website .headr_second .next_two_btn {
      width: 29%;
      height: 240px;
    }

    .medical_website .headr_second .next_two_btn .book_appointm_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .next_two_btn .book_appointm_btn h4 {
      font-size: 11px;
    }

    .medical_website .headr_second .next_two_btn .book_appointm_btn h5 {
      font-size: 12px;
      font-weight: 600;
    }

    .medical_website .headr_second .next_two_btn .free_consult_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .next_two_btn .free_consult_btn h4 {
      font-size: 11px;
    }

    .medical_website .headr_second .next_two_btn .free_consult_btn h5 {
      font-size: 12px;
      font-weight: 600;
    }

    .medical_website .headr_second .last_two_btn {
      width: 46%;
      height: 240px;
    }

    .medical_website .headr_second .last_two_btn .instant_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .last_two_btn .instant_btn h3 {
      font-size: 12px;
    }

    .medical_website .headr_second .last_two_btn .instant_btn h5 {
      font-size: 12px;
      font-weight: 600;
    }

    .medical_website .headr_second .last_two_btn .instant_btn .houstonisd {
      margin-left: 80px;
      object-fit: contain;
    }

    .medical_website .headr_second .last_two_btn .instant_btn .image_instan {
      margin-right: 15px;
      /* object-fit: contain; */
    }

    .medical_website .headr_second .last_two_btn .free_instant_btn {
      width: 100%;
      height: 47%;
      border-radius: 10px;
    }

    .medical_website .headr_second .last_two_btn .free_instant_btn h4 {
      font-size: 12px;
    }

    .medical_website .headr_second .last_two_btn .free_instant_btn h5 {
      font-size: 12px;
      font-weight: 600;
    }


    .categories {
      padding: 25px 35px 25px 35px;
    }

    .categories h4 {
      margin-bottom: 15px;
    }

    .category_item .imageKo_circlw {
      height: 70px;
      width: 70px;
    }

    .category_item img {
      width: 70%;
      height: 70%;
    }

    .doc_text_sect {
      padding: 45px 30px 0px 30px;
    }


    .medical_website .playstore_appsign {
      padding: 15px 60px 15px 60px;
    }

    .medical_website .playstore_appsign .image_playstore {
      width: 40%;
    }
  }

  /* /////////////////////////////////////// */