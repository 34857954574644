.modal_paymentoptionground {
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  height: 100%;
  z-index: 1;
}

.modal_paymentoptionground .modalpaymentoptionContainer {
  width: 35%;
  height: 510px;
  border-radius: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

.modal_paymentoptionground .modalpaymentoptionContainer::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

.modal_paymentoptionground .modalpaymentoptionContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.modal_paymentoptionground .modalpaymentoptionContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #874daf;
}

.modalpaymentoptionContainer .titlepaymentCloseBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px 0px 25px;
}

.modalpaymentoptionContainer .titlepaymentCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modal_paymentoptionground .modalpaymentoptionContainer .just_h5 h5 {
  color: gray;
  font-weight: 400;
  margin-left: 15px;
}

.modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  margin-top: 15px;
  margin-left: 10px;
}

.modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .anewdivinside {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .insurance {
  width: 25px;
  height: 25px;
  object-fit: cover;
}

.modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .upr_nic_nam {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .upr_nic_nam h4 {
  color: black;
  font-weight: 600;
  font-size: 16px;
}

.modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .upr_nic_nam h5 {
  color: gray;
  font-weight: 400;
  font-size: 11px;
}

.modal_paymentoptionground .modalpaymentoptionContainer .line {
  height: 1px;
  background-color: lightgray;
  border: none;
  margin-top: 10px;
}


.modal_paymentoptionground .modalpaymentoptionContainer .last_promo_butun {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7752c2;
  padding: 12px 0px 12px 0px;
  width: 85%;
  align-self: center;
  border-radius: 7px;
  position: absolute;
  bottom: 15px;
}

.modal_paymentoptionground .modalpaymentoptionContainer .last_promo_butun h4 {
  color: white;
}



/* /////////////// */
.Promo_Dialog {
  width: 100%;
  height: 100%;
}

.Promo_Dialog .Promo_dialog_title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 60px;
  padding-right: 60px;
  color: #874daf;
}

.Promo_Dialog .Promo_Content_Inpu {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.Promo_Dialog .Promo_Content_Inpu .promo_inpu {
  width: 100%;
  border: none;
  background-color: white;
  height: 35px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1.5px solid gray;
}

.Promo_Dialog .Promo_Ok_nd_Close {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid lightgray;
  margin-top: 10px;
}

.Promo_Dialog .Promo_Ok_nd_Close .Promo_Ok {
  width: 47%;
  color: #874daf;
}

.Promo_Dialog .Promo_Ok_nd_Close .line {
  border-left: 1px solid lightgray;
  width: 0.5px;
  height: 35px;
}

.Promo_Dialog .Promo_Ok_nd_Close .Promo_Close {
  width: 47%;
  color: #874daf;
}

/* ....................... */



/* .......... */
.modal_paymentoptionground .spinner_main_loader {
  width: 100%;
  height: 100%;
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.modal_paymentoptionground .spinner_main_loader .spinner_inside {
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
}







@media only screen and (min-width: 0px) and (max-width: 431px) {
  .modal_paymentoptionground .modalpaymentoptionContainer {
    width: 90%;
  }

  .modalpaymentoptionContainer .titlepaymentCloseBtn {
    padding: 13px 18px 0px 18px;
  }

  .modalpaymentoptionContainer .titlepaymentCloseBtn button {
    font-size: 18px;
  }

  .modalpaymentoptionContainer .titlepaymentCloseBtn h2 {
    font-size: 18px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .just_h5 h5 {
    font-size: 10px;
    margin-left: 10px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .upr_nic_nam {
    margin-left: 10px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .upr_nic_nam h4 {
    font-size: 13px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .upr_nic_nam h5 {
    font-size: 9px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .last_promo_butun {
    padding: 8px 0px 8px 0px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .last_promo_butun h4 {
    font-size: 14px;
  }

  .Promo_Dialog .Promo_dialog_title {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 30px;
    padding-right: 30px;
  }

}



@media only screen and (min-width: 431px) and (max-width: 680px) {
  .modal_paymentoptionground .modalpaymentoptionContainer {
    width: 65%;
  }

  .modalpaymentoptionContainer .titlepaymentCloseBtn {
    padding: 15px 20px 0px 20px;
  }

  .modalpaymentoptionContainer .titlepaymentCloseBtn button {
    font-size: 20px;
  }

  .modalpaymentoptionContainer .titlepaymentCloseBtn h2 {
    font-size: 20px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .just_h5 h5 {
    font-size: 11px;
    margin-left: 12px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .upr_nic_nam {
    margin-left: 12px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .upr_nic_nam h4 {
    font-size: 14px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .pay_wih_card .upr_nic_nam h5 {
    font-size: 10px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .last_promo_butun {
    padding: 10px 0px 10px 0px;
  }

  .modal_paymentoptionground .modalpaymentoptionContainer .last_promo_butun h4 {
    font-size: 15px;
  }
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
  .modal_paymentoptionground .modalpaymentoptionContainer {
    width: 50%;
  }
}