.appointment_startup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}


/* ////////////////////////// */
.appointment_startup .buttonContainer {
  width: 85%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.appointment_startup .buttonContainer .activeButton {
  background-color: #874DAF;
  color: white;
  padding: 12px 45px;
  border-radius: 5px;
  border: 3px solid #874DAF;
}

.appointment_startup .buttonContainer .inactiveButton {
  background-color: white;
  color: #874DAF;
  padding: 12px 45px;
  border-radius: 5px;
  border: 1.5px solid #874DAF;
}

/* ///////////////////// */




/* ............... */
.appointment_startup .viewofchange_btn {
  width: 80%;
  height: 45px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 25px;
  padding-left: 3px;
  padding-right: 3px;
  justify-content: center;
}

.appointment_startup .viewofchange_btn .insideforbaccolr {
  display: flex;
  flex-direction: row;
  background-color: lightgray;
  height: 100%;
  padding: 4px;
  border-radius: 8px;
}

.appointment_startup .viewofchange_btn .insideforbaccolr .active_button {
  background-color: white;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
}

.appointment_startup .viewofchange_btn .insideforbaccolr .active_button h4 {
  color: gray;
}

.appointment_startup .viewofchange_btn .insideforbaccolr .in_active_button {
  background-color: lightgray;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
}

.appointment_startup .viewofchange_btn .insideforbaccolr .in_active_button h4 {
  color: gray;
}

.appointment_startup .viewofchange_btn .insideforbaccolr .in_active_button:hover {
  /* background-color: white; */
  /* box-shadow: 0px 2px 10px #c5c5c5; */
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
}

.appointment_startup .viewofchange_btn .insideforbaccolr .in_active_button:hover h4 {
  color: gray;
}

/* ............. */


.appointment_startup .skelton_appoint_div {
  width: 80%;
  border-radius: 5px;
}

.appointment_startup .skelton_appoint_div .sklnappointheight {
  height: 230px;
  margin-bottom: 20px;
}


.appointment_startup .one_appointment_card {
  width: 80%;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 15px 15px 10px 15px;
  box-shadow: 0px 2px 10px #c5c5c5;
}

.appointment_startup .one_appointment_card .outsidetyprcheck h4 {
  margin-left: 10px;
  color: #00c480;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  border-color: #874daf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image .ima_apoint_doc {
  width: 57px;
  height: 57px;
  border-radius: 100px;
  object-fit: cover;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc .name_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc .date_status_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc .date_status_top h5 {
  color: gray;
}


.appointment_startup .one_appointment_card .viws_detais {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-top: -30px;
  padding-right: 10px;
  color: #4b738c;
}

.appointment_startup .one_appointment_card .categ_detais {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding-left: 20%;
  padding-right: 10px;
}


.appointment_startup .one_appointment_card .categ_detais #redid {
  color: red;
}

.appointment_startup .one_appointment_card .categ_detais #noredid {
  color: #4b738c;
}

.appointment_startup .one_appointment_card .categ_detais #imidiatrspsdoc {
  color: red;
}

.appointment_startup .one_appointment_card .categ_detais #vidoeicn {
  color: #874DAF;
}

.appointment_startup .one_appointment_card .categ_detais h4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: #4b738c;
}

.appointment_startup .one_appointment_card .Paid_progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.appointment_startup .one_appointment_card .Paid_progress h4 {
  color: #00c480;
}

.appointment_startup .one_appointment_card .Paid_progress .doted_sta {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: #00c480;
  margin-right: 7px;
  margin-top: 3px;
}

.appointment_startup .one_appointment_card .line {
  height: 1px;
  background-color: lightgray;
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
}


.appointment_startup .one_appointment_card .last_cancel_nd_reschedule {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 100px;
  padding: 10px 20px 10px 20px;
  border: 2px solid #874daf;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel h3 {
  color: #874daf;
  font-size: 16px;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #874daf;
  border-radius: 100px;
  padding: 10px 20px 10px 20px;
  border: 2px solid #874daf;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule h3 {
  color: white;
  font-size: 16px;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  border-radius: 100px;
  padding: 10px 40px 10px 40px;
  border: 2px solid gray;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview h3 {
  color: white;
  font-size: 16px;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #874daf;
  border-radius: 100px;
  padding: 10px 40px 10px 40px;
  border: 2px solid #874daf;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview h3 {
  color: white;
  font-size: 16px;
}



/* Rbsheet */
.bottom-sheet-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transform: translateY(100%);
  transition: transform 0.3s;
}

.bottom-sheet-overlay.open {
  transform: translateY(0);
}

.cancel_sheet_content {
  background-color: white;
  width: 45%;
  padding: 30px;
  border-radius: 40px 40px 0 0;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cancel_sheet_content h2 {
  color: red;
}

.cancel_sheet_content .line {
  height: 1px;
  width: 100%;
  background-color: lightgray;
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
}

.cancel_sheet_content p {
  color: gray;
  font-weight: bold;
  max-width: 70%;
  text-align: center;
}

.cancel_sheet_content .twobtn_cancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
}

.cancel_sheet_content .twobtn_cancel .close {
  background-color: #eef3ff;
  color: #874DAF;
  border-radius: 100px;
  border: none;
  padding: 13px 40px 13px 40px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cancel_sheet_content .twobtn_cancel .yescancel {
  background-color: #874daf;
  color: white;
  border-radius: 100px;
  border: none;
  padding: 13px 40px 13px 40px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}





@media only screen and (min-width: 0px) and (max-width: 431px) {
  .appointment_startup .buttonContainer {
    width: 100%;
    margin: 15px auto;
    gap: 8px;
  }

  .appointment_startup .buttonContainer .activeButton {
    padding: 8px 32px;
    border: 1px solid #874DAF;
  }

  .appointment_startup .buttonContainer .inactiveButton {
    padding: 8px 32px;
    border: 1px solid #874DAF;
  }

  .appointment_startup .viewofchange_btn {
    width: 95%;
    height: 32px;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 0px;
  }

  .appointment_startup .viewofchange_btn .active_button {
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 8px;
  }

  .appointment_startup .viewofchange_btn .active_button h4 {
    font-size: 11px;
  }

  .appointment_startup .viewofchange_btn .in_active_button {
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 8px;
  }

  .appointment_startup .viewofchange_btn .in_active_button h4 {
    font-size: 11px;
  }

  .appointment_startup .skelton_appoint_div {
    width: 95%;
  }

  .appointment_startup .skelton_appoint_div .sklnappointheight {
    height: 150px;
  }

  .appointment_startup .one_appointment_card {
    width: 95%;
    padding: 10px 10px 8px 10px;
  }

  .appointment_startup .one_appointment_card .outsidetyprcheck h4 {
    margin-left: 0px;
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image {
    width: 55px;
    height: 55px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image .ima_apoint_doc {
    width: 45px;
    height: 45px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .name_top h3 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .name_top h4 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .date_status_top h5 {
    font-size: 11px;
  }

  .appointment_startup .one_appointment_card .categ_detais {
    padding: 0px;
  }

  .appointment_startup .one_appointment_card .categ_detais h4 {
    gap: 2px;
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .Paid_progress {
    padding-left: 2px;
    padding-right: 0px;
  }

  .appointment_startup .one_appointment_card .Paid_progress h4 {
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .line {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule {
    padding-left: 5px;
    margin-bottom: 5px;
  }


  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel {
    padding: 6px 8px 6px 8px;
    border: 1px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel h3 {
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule {
    padding: 6px 8px 6px 8px;
    border: 1px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule h3 {
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview {
    padding: 6px 20px 6px 20px;
    border: 1px solid gray;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview h3 {
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview {
    padding: 6px 20px 6px 20px;
    border: 1px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview h3 {
    font-size: 13px;
  }

  .cancel_sheet_content {
    width: 90%;
    padding: 15px;
    border-radius: 50px 50px 0 0;
  }

  .cancel_sheet_content h2 {
    font-size: 16px;
  }

  .cancel_sheet_content .line {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .cancel_sheet_content p {
    font-size: 12px;
    max-width: 80%;
  }

  .cancel_sheet_content .twobtn_cancel {
    margin-top: 8px;
    margin-bottom: 3px;
  }

  .cancel_sheet_content .twobtn_cancel .close {
    padding: 10px 25px 10px 25px;
  }

  .cancel_sheet_content .twobtn_cancel .yescancel {
    padding: 10px 25px 10px 25px;
  }
}



@media only screen and (min-width: 431px) and (max-width: 680px) {
  .appointment_startup .buttonContainer .activeButton {
    padding: 10px 35px;
    border: 2.5px solid #874DAF;
  }

  .appointment_startup .buttonContainer .inactiveButton {
    padding: 10px 35px;
    border: 1.4px solid #874DAF;
  }

  .appointment_startup .viewofchange_btn {
    width: 90%;
    height: 35px;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 0px;
  }

  .appointment_startup .viewofchange_btn .active_button {
    padding-right: 12px;
    padding-left: 12px;
  }

  .appointment_startup .viewofchange_btn .active_button h4 {
    font-size: 13px;
  }

  .appointment_startup .viewofchange_btn .in_active_button {
    padding-right: 12px;
    padding-left: 12px;
  }

  .appointment_startup .viewofchange_btn .in_active_button h4 {
    font-size: 13px;
  }

  .appointment_startup .skelton_appoint_div {
    width: 90%;
  }

  .appointment_startup .skelton_appoint_div .sklnappointheight {
    height: 180px;
  }

  .appointment_startup .one_appointment_card {
    width: 90%;
    padding: 12px 12px 10px 12px;
  }

  .appointment_startup .one_appointment_card .outsidetyprcheck h4 {
    margin-left: 5px;
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image {
    width: 56px;
    height: 56px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image .ima_apoint_doc {
    width: 47px;
    height: 47px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .name_top h3 {
    font-size: 15px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .name_top h4 {
    font-size: 15px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .date_status_top h5 {
    font-size: 12px;
  }

  .appointment_startup .one_appointment_card .categ_detais {
    padding-left: 3px;
    padding-right: 5px;
  }

  .appointment_startup .one_appointment_card .categ_detais h4 {
    gap: 3px;
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .Paid_progress {
    padding-left: 5px;
    padding-right: 5px;
  }

  .appointment_startup .one_appointment_card .Paid_progress h4 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .line {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule {
    padding-left: 10px;
    margin-bottom: 5px;
  }


  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel {
    padding: 8px 10px 8px 10px;
    border: 1.5px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel h3 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule {
    padding: 8px 10px 8px 10px;
    border: 1.5px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule h3 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview {
    padding: 8px 30px 8px 30px;
    border: 1.5px solid gray;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview h3 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview {
    padding: 8px 30px 8px 30px;
    border: 1.5px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview h3 {
    font-size: 14px;
  }

  .cancel_sheet_content {
    width: 75%;
    padding: 15px;
    border-radius: 50px 50px 0 0;
  }

  .cancel_sheet_content h2 {
    font-size: 16px;
  }

  .cancel_sheet_content .line {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .cancel_sheet_content p {
    font-size: 12px;
    max-width: 80%;
  }

  .cancel_sheet_content .twobtn_cancel {
    margin-top: 8px;
    margin-bottom: 3px;
  }

  .cancel_sheet_content .twobtn_cancel .close {
    padding: 10px 30px 10px 30px;
  }

  .cancel_sheet_content .twobtn_cancel .yescancel {
    padding: 10px 30px 10px 30px;
  }
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
  .cancel_sheet_content {
    width: 55%;
    padding: 20px;
    border-radius: 50px 50px 0 0;
  }

  .cancel_sheet_content h2 {
    font-size: 16px;
  }

  .cancel_sheet_content .line {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .cancel_sheet_content p {
    font-size: 14px;
    max-width: 80%;
  }

  .cancel_sheet_content .twobtn_cancel {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .cancel_sheet_content .twobtn_cancel .close {
    padding: 12px 38px 12px 38px;
  }

  .cancel_sheet_content .twobtn_cancel .yescancel {
    padding: 12px 38px 12px 38px;
  }
}




/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* Doctor Appointment Style */

.docsitnamfist {
  color: black;
  font-size: 15px;
  font-weight: bold;
}

.botnkatext {
  color: black;
  font-weight: bold;
}

.dockaimgkasect {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.dockaimgkasect .pahalimg {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  border-color: #874DAF;
  justify-content: center;
  align-items: center;
}

.dockaimgkasect .pahalimg .oknowimgin {
  width: 55px;
  height: 55px;
  border-radius: 50px;
}

.dockaimgkasect .akriinimg {
  flex-direction: column;
  margin-left: 8px;
  margin-top: 4px;
}

.dockaimgkasect .akriinimg #nownmofusr {
  color: black;
  font-size: 15px;
  font-weight: bold;
}

.dockaimgkasect .akriinimg #nowadofusr {
  color: #39b4cc;
  font-size: 15px;
}

.opendatetimemix {
  color: #4b738c;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-left: 60px;
  margin-bottom: 10px;
}

.lasttwobtndoc {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.lasttwobtndoc .fistcallbutn {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  border-radius: 6px;
  padding: 5px 15px 5px 15px;
  background-color: lightgray;
}

.lasttwobtndoc .fistcallbutn .maincallbutn {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: #f3f4f4;
  justify-content: center;
  align-items: center;
  border: none;
}

.lasttwobtndoc .fistcallbutn .textsizcalicn {
  color: #0751fc;
  font-size: 25px;
}

.lasttwobtndoc .secndchtbutn {
  display: flex;
  margin-left: 10px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 8px;
  padding: 6px;
  border: 2px solid #874DAF;
}

.lasttwobtndoc .secndchtbutn .iconsizcht {
  color: #874DAF;
  font-size: 16px;
}

.lasttwobtndoc .secndchtbutn .sizofchattxt {
  color: #874DAF;
  font-weight: bold;
  margin-left: 5px;
}






@media only screen and (min-width: 0px) and (max-width: 431px) {
  .docsitnamfist {
    font-size: 13px;
  }

  .botnkatext {
    font-size: 12px;
  }

  .dockaimgkasect {
    padding-top: 10px;
  }

  .dockaimgkasect .pahalimg {
    width: 45px;
    height: 45px;
  }

  .dockaimgkasect .pahalimg .oknowimgin {
    width: 45px;
    height: 45px;
  }

  .dockaimgkasect .akriinimg {
    margin-left: 5px;
    margin-top: 2px;
  }

  .dockaimgkasect .akriinimg #nownmofusr {
    font-size: 13px;
  }

  .dockaimgkasect .akriinimg #nowadofusr {
    font-size: 13px;
  }

  .opendatetimemix {
    gap: 2px;
    font-size: 13px;
    margin-left: 50px;
    margin-bottom: 3px;
  }

  .lasttwobtndoc {
    margin-top: 5px;
  }

  .lasttwobtndoc .fistcallbutn {
    margin-left: 7px;
    padding: 3px 10px 3px 10px;
  }

  .lasttwobtndoc .fistcallbutn .maincallbutn {
    width: 30px;
    height: 30px;
  }

  .lasttwobtndoc .fistcallbutn .textsizcalicn {
    font-size: 20px;
  }

  .lasttwobtndoc .secndchtbutn {
    margin-left: 7px;
    margin-top: 7px;
    padding: 4px;
    border: 1.5px solid #874DAF;
  }

  .lasttwobtndoc .secndchtbutn .iconsizcht {
    font-size: 14px;
  }

  .lasttwobtndoc .secndchtbutn .sizofchattxt {
    margin-left: 3px;
  }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
  .docsitnamfist {
    font-size: 14px;
  }

  .botnkatext {
    font-size: 13px;
  }

  .dockaimgkasect {
    padding-top: 15px;
  }

  .dockaimgkasect .pahalimg {
    width: 50px;
    height: 50px;
  }

  .dockaimgkasect .pahalimg .oknowimgin {
    width: 50px;
    height: 50px;
  }

  .dockaimgkasect .akriinimg {
    margin-left: 7px;
    margin-top: 3px;
  }

  .dockaimgkasect .akriinimg #nownmofusr {
    font-size: 14px;
  }

  .dockaimgkasect .akriinimg #nowadofusr {
    font-size: 14px;
  }

  .opendatetimemix {
    gap: 3px;
    font-size: 14px;
    margin-left: 55px;
    margin-bottom: 5px;
  }

  .lasttwobtndoc {
    margin-top: 7px;
  }

  .lasttwobtndoc .fistcallbutn {
    margin-left: 10px;
    padding: 4px 12px 4px 12px;
  }

  .lasttwobtndoc .fistcallbutn .maincallbutn {
    width: 35px;
    height: 35px;
  }

  .lasttwobtndoc .fistcallbutn .textsizcalicn {
    font-size: 22px;
  }

  .lasttwobtndoc .secndchtbutn {
    margin-left: 8px;
    margin-top: 8px;
    padding: 5px;
    border: 1.5px solid #874DAF;
  }

  .lasttwobtndoc .secndchtbutn .iconsizcht {
    font-size: 15px;
  }

  .lasttwobtndoc .secndchtbutn .sizofchattxt {
    margin-left: 4px;
  }
}








/* ////////////////////////////////////////////////////Consult Desgin///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
.consult_patient_startup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.consult_toptwo_tutton {
  width: 40%;
  background-color: white;
  border-radius: 10px;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
  padding-top: 3px;
  padding-bottom: 3px;
}

.consult_toptwo_tutton .activeProcess {
  width: 49%;
  height: 45px;
  background-color: #874DAF;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.consult_toptwo_tutton .inactiveProcess {
  background-color: white;
  width: 49%;
  height: 45px;
  display: flex;
  border: none;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
}

.consult_toptwo_tutton .processtext {
  color: white;
}

.consult_toptwo_tutton .inprocesstext {
  color: #874DAF;
}



/* Card flatlist */
.consult_patient_startup .skelton_appoint_div {
  width: 80%;
  margin: 20px auto;
}

.consult_patient_startup .skelton_appoint_div .sklnconsltheight {
  height: 200px;
  border-radius: 10px;
  margin-bottom: 20px;
}


.consult_patient_startup .mainpatientinside {
  width: 80%;
  background-color: white;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
  padding: 15px;
}

.consult_patient_startup .mainpatientinside .firstrowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst {
  display: flex;
  align-items: center;
  gap: 10px;
}

.consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #userconsult {
  color: black;
  font-size: 15px;
}

.consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #datee2 {
  color: #874DAF;
  font-size: 12px;
}

.consult_patient_startup .mainpatientinside .firstrowContainer .borderedButton {
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  border: 2px solid #874DAF;
}


.consult_patient_startup .mainpatientinside .profileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
}

.consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  border: 2px solid #874DAF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer .imagemainin {
  width: 55px;
  height: 55px;
  border-radius: 100px;
}

.consult_patient_startup .mainpatientinside .profileContainer .columnContainer {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-top: 4px;
}

.consult_patient_startup .mainpatientinside .profileContainer .columnContainer #doctornaemconslt {
  color: black;
  font-weight: bold;
  font-size: 15px;
}

.consult_patient_startup .mainpatientinside .profileContainer .columnContainer #docadrsconslt {
  color: #39b4cc;
  font-size: 15px;
}

.consult_patient_startup .mainpatientinside .dateTimeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 50px;
  gap: 5px;
  margin-top: 10px;
}

.consult_patient_startup .mainpatientinside .dateTimeContainer #Calendarmonthcon {
  font-size: 14px;
  color: #4b738c;
}

.consult_patient_startup .mainpatientinside .statusContainer {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}

.consult_patient_startup .mainpatientinside .statusContainer #feeinconsult {
  color: black;
  font-weight: bold;
  font-size: 15px;
}




@media only screen and (min-width: 0px) and (max-width: 431px) {
  .consult_toptwo_tutton {
      width: 70%;
      padding-top: 2px;
      padding-bottom: 2px;
  }

  .consult_toptwo_tutton .activeProcess {
      width: 49%;
      height: 40px;
  }

  .consult_toptwo_tutton .inactiveProcess {
      background-color: white;
      width: 49%;
      height: 40px;
  }

  .consult_toptwo_tutton .processtext {
      font-size: 11px;
  }

  .consult_toptwo_tutton .inprocesstext {
      font-size: 11px;
  }

  .consult_patient_startup .skelton_appoint_div {
      width: 95%;
      margin: 5px auto;
  }

  .consult_patient_startup .skelton_appoint_div .sklnconsltheight {
      height: 140px;
      margin-bottom: 20px;
  }

  .consult_patient_startup .mainpatientinside {
      width: 95%;
      margin: 5px auto;
      padding: 10px;
  }

  .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst {
      gap: 4px;
  }

  .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #userconsult {
      font-size: 12px;
  }

  .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #datee2 {
      font-size: 9px;
  }

  .consult_patient_startup .mainpatientinside .firstrowContainer .borderedButton {
      padding: 4px;
      border-radius: 5px;
      border: 1.5px solid #874DAF;
      font-size: 11px;
  }


  .consult_patient_startup .mainpatientinside .profileContainer {
      padding-top: 5px;
  }

  .consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer {
      width: 50px;
      height: 50px;
      border: 1.5px solid #874DAF;
  }

  .consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer .imagemainin {
      width: 45px;
      height: 45px;
  }

  .consult_patient_startup .mainpatientinside .profileContainer .columnContainer {
      margin-left: 5px;
      margin-top: 0px;
  }

  .consult_patient_startup .mainpatientinside .profileContainer .columnContainer #doctornaemconslt {
      font-size: 13px;
  }

  .consult_patient_startup .mainpatientinside .profileContainer .columnContainer #docadrsconslt {
      color: #39b4cc;
      font-size: 13px;
  }

  .consult_patient_startup .mainpatientinside .dateTimeContainer {
      margin-left: 35px;
      margin-top: 6px;
  }

  .consult_patient_startup .mainpatientinside .dateTimeContainer #Calendarmonthcon {
      font-size: 12px;
  }

  .consult_patient_startup .mainpatientinside .statusContainer {
      margin-top: 1px;
  }

  .consult_patient_startup .mainpatientinside .statusContainer #feeinconsult {
      font-size: 13px;
  }

  .consult_patient_startup .mainpatientinside .statusContainer #dowithstatus {
      font-size: 13px;
  }
}




@media only screen and (min-width: 431px) and (max-width: 680px) {
  .consult_toptwo_tutton {
      width: 55%;
      padding-top: 2px;
      padding-bottom: 2px;
  }

  .consult_toptwo_tutton .activeProcess {
      width: 49%;
      height: 42px;
  }

  .consult_toptwo_tutton .inactiveProcess {
      background-color: white;
      width: 49%;
      height: 42px;
  }

  .consult_toptwo_tutton .processtext {
      font-size: 12px;
  }

  .consult_toptwo_tutton .inprocesstext {
      font-size: 12px;
  }

  .consult_patient_startup .skelton_appoint_div {
      width: 90%;
      margin: 10px auto;
  }

  .consult_patient_startup .skelton_appoint_div .sklnconsltheight {
      height: 180px;
      margin-bottom: 20px;
  }

  .consult_patient_startup .mainpatientinside {
      width: 90%;
      margin: 10px auto;
      padding: 12px;
  }

  .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst {
      gap: 6px;
  }

  .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #userconsult {
      font-size: 14px;
  }

  .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #datee2 {
      font-size: 11px;
  }

  .consult_patient_startup .mainpatientinside .firstrowContainer .borderedButton {
      padding: 6px;
      border: 2px solid #874DAF;
      font-size: 12px;
  }


  .consult_patient_startup .mainpatientinside .profileContainer {
      padding-top: 10px;
  }

  .consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer {
      width: 55px;
      height: 55px;
      border: 1.5px solid #874DAF;
  }

  .consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer .imagemainin {
      width: 50px;
      height: 50px;
  }

  .consult_patient_startup .mainpatientinside .profileContainer .columnContainer {
      margin-left: 7px;
      margin-top: 0px;
  }

  .consult_patient_startup .mainpatientinside .dateTimeContainer {
      margin-left: 45px;
      margin-top: 8px;
  }

  .consult_patient_startup .mainpatientinside .dateTimeContainer #Calendarmonthcon {
      font-size: 13px;
  }

  .consult_patient_startup .mainpatientinside .statusContainer {
      margin-top: 2px;
  }

  .consult_patient_startup .mainpatientinside .statusContainer #feeinconsult {
      font-size: 14px;
  }

  .consult_patient_startup .mainpatientinside .statusContainer #dowithstatus {
      font-size: 14px;
  }
}