.Doctorhome_website {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
}


/* Header top section */
.Doctorhome_website .docto_hometop {
    width: 100%;
    padding: 20px 50px 20px 50px;
}

.Doctorhome_website .docto_hometop .logo_dochome_name {
    display: flex;
    align-items: center;
}

.Doctorhome_website .docto_hometop .consult_onof {
    display: flex;
    flex-direction: row;
    margin-left: 5px;
}

.Doctorhome_website .docto_hometop .consult_onof h4 {
    margin-right: 10px;
    color: gray;
    font-size: 16px;
}


.Doctorhome_website .docto_hometop .logo_dochome_name .circle_logo {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #874daf;
}

.Doctorhome_website .docto_hometop .logo_dochome_name .circle_logo .image_logo {
    width: 30px;
    height: 30px;
    filter: brightness(5) saturate(0);
}

.Doctorhome_website .docto_hometop .logo_dochome_name h4 {
    margin-left: 10px;
    font-weight: 500;
}

.Doctorhome_website .docto_hometop h3 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 17px;
    margin-left: 5px;
    margin-bottom: 25px;
}

/* /////////////////////////// */



/* Pending Appointment */
.Doctorhome_website .allpendingappointm {
    width: 91%;
    border-radius: 10px;
    /* padding: 15px 0px 15px 0px; */
    overflow: auto;
    margin-top: 10px;
}

.Doctorhome_website .allpendingappointm h3 {
    color: #014e78;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo {
    display: flex;
    align-items: center;
    overflow: auto;
    gap: 10px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo::-webkit-scrollbar {
    width: 12px;
    height: 4px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
}


.Doctorhome_website .allpendingappointm .maindivpendingappo .pending_appoi {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 15px 10px 15px;
    gap: 10px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .pending_appoi .skeltofpendngupcm {
    width: 55vh;
    height: 183px;
    border-radius: 12px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .nopenrecorddiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .nopenrecorddiv h4 {
    color: black;
    align-self: center;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain {
    width: 55vh;
    border-radius: 12px;
    background-color: white;
    margin: 5px 5px 5px 5px;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
    padding-top: 3px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcomimge {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: contain;
}

.see_detailbutton {
    width: 100%;
    padding: 7px;
    background-color: #874DAF;
    color: white;
    text-align: center;
    margin-top: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-width: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde {
    margin-left: 10px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .poftext {
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .poftext .spanoftet {
    color: #00c480;
    font-weight: bold;
    font-size: 10px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .clicoronlne {
    color: lightgray;
    font-size: 10px;
    text-decoration: underline;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .feofupcom {
    color: white;
    margin-top: -10px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .datofupcome {
    color: white;
    font-size: 11px;
    margin-top: 22px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 5px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .timeicon {
    color: white;
    font-size: 14px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .timeoftextin {
    color: white;
    font-size: 11px;
    margin-left: 5px;
}

.Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .statusof {
    color: lightgray;
    font-size: 10px;
    margin-left: 5px;
}

/* ................................. */



/* All Appointment */
.Doctorhome_website .allappointforaccept .maindivallappo .request_appoi {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px 15px 10px 15px;
    gap: 10px;
}

.Doctorhome_website .allappointforaccept .maindivallappo .request_appoi .skeltdochmapt {
    width: 45vh;
    height: 190px;
    border-radius: 8px;
}

.Doctorhome_website .allappointforaccept {
    width: 91%;
    border-radius: 10px;
    overflow: auto;
    margin-top: 20px;
}

.Doctorhome_website .allappointforaccept h3 {
    color: #014e78;
}

.Doctorhome_website .allappointforaccept .maindivallappo {
    display: flex;
    align-items: center;
    overflow: auto;
    gap: 10px;
}

.Doctorhome_website .allappointforaccept .maindivallappo::-webkit-scrollbar {
    width: 12px;
    height: 4px;
}

.Doctorhome_website .allappointforaccept .maindivallappo::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.Doctorhome_website .allappointforaccept .maindivallappo::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
}

.Doctorhome_website .allappointforaccept .maindivallappo .norecorddiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.Doctorhome_website .allappointforaccept .maindivallappo .norecorddiv h4 {
    color: black;
    align-self: center;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman {
    background-color: white;
    width: 45vh;
    align-self: center;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
    margin: 5px 7px 0px 7px;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .cliconoffreshl {
    color: #00c480;
    font-size: 13px;
    font-weight: bold;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .unameofacpt {
    color: black;
    font-weight: bold;
    font-size: 16px;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .acatrgoryofacpt {
    color: #bebebe;
    font-size: 14px;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .afeeofacpt {
    color: #874DAF;
    font-size: 13px;
    font-weight: bold;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .cliconoffre {
    color: #00c480;
    font-size: 15px;
    font-weight: bold;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .reschilforacpt {
    color: #874DAF;
    font-size: 11px;
    font-weight: bold;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .imaginaceptapoint {
    width: 60px;
    height: 60px;
    border-radius: 8px;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .lininacpt {
    border-bottom: 1.5px solid #ddd;
    margin-top: 10px;
    margin-bottom: 5px;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .calendrmothicn {
    color: gray;
    font-size: 14px;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .dattimixacpt {
    color: gray;
    font-size: 12px;
    margin-left: 5px;
}

.Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .btnofacept {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #874DAF;
    padding: 10px;
    color: white;
    font-size: 15px;
    border: none;
    margin-top: 10px;
}

/* ................................... */



/* ................................. */


.Doctorhome_website .spaceindocorsumins {
    width: 100%;
    margin-top: 90px;
}



/* Services Section */
.services-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 10px auto;
}

.service {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    max-width: 300px;
    text-align: center;
}

.service i {
    font-size: 3rem;
    margin-bottom: 10px;
}

.service h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.service p {
    font-size: 1rem;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .services-section {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 20px auto;
    }

    .service {
        max-width: 100%;
        margin: 20px 0;
    }

    .service i {
        font-size: 2rem;
    }

    .service h2 {
        font-size: 1.2rem;
    }

    .service p {
        font-size: 0.9rem;
    }
}

/*  */




/* Play store mobile app link */
.Doctorhome_website .playstore_appsign {
    width: 91%;
    height: 250px;
    background-color: #e8f6f9;
    border-radius: 10px;
    padding: 15px 150px 15px 150px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.Doctorhome_website .playstore_appsign .image_playstore {
    width: 30%;
    height: 100%;
    object-fit: contain;
}

.Doctorhome_website .playstore_appsign .name_ty_logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Doctorhome_website .playstore_appsign .name_ty_logo .circule_for_logo {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #874daf;
}

.Doctorhome_website .playstore_appsign .name_ty_logo .circule_for_logo .imf_log {
    width: 25px;
    height: 25px;
    filter: brightness(5) saturate(0);
}

.Doctorhome_website .playstore_appsign .name_ty_logo h4 {
    color: #874daf;
    font-weight: bold;
    margin-left: 5px;
}

.Doctorhome_website .playstore_appsign .twoo_three_divs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    margin-left: 10px;
}

.Doctorhome_website .playstore_appsign .twoo_three_divs h5 {
    color: #014e78;
    margin-left: 12px;
}

.Doctorhome_website .playstore_appsign .two_btn_goplay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 20px;
}

.Doctorhome_website .playstore_appsign .two_btn_goplay .PlayStore {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    border-radius: 5px;
    height: 40px;
    width: 130px;
    padding: 5px 5px 5px 5px;
}

.Doctorhome_website .playstore_appsign .two_btn_goplay .PlayStore .playstore_img {
    width: 20px;
    height: 20px;
}

.Doctorhome_website .playstore_appsign .two_btn_goplay .PlayStore h6 {
    color: white;
    font-size: 8px;
    font-weight: 400;
}

.Doctorhome_website .playstore_appsign .two_btn_goplay .PlayStore h4 {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-top: -3px;
}




/* Footer Section */
.footer_top {
    width: 100%;
    margin-top: 10px;
    background-color: #1b71a1;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer_second_end {
    width: 100%;
    height: 400px;
    background-color: #1b71a1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 90px 0px 75px;
}

.footer_second_end .p_app_name {
    color: white;
    font-size: 45px;
}

.footer_second_end .text_light {
    color: white;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 300;
}

.footer_second_end .footor_inside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    text-decoration: none;
}

.footer_second_end .footor_inside .Departments {
    color: white;
    font-size: 20px;
    margin-bottom: 30px;
}

.footer_second_end .footor_inside ul {
    text-decoration: none;
}

.footer_second_end .footor_inside ul li {
    padding: 10px 0px 10px 0px;
    list-style: none;
}

.footer_second_end .footor_inside ul li a {
    color: lightgray;
    text-decoration: none;
}


.footer_end {
    width: 100%;
    background-color: #01334e;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer_end p {
    color: white;
}

.footer_end p a {
    color: #1688b0;
}

/* //////////////////////////////////// */



.Doctorhome_website .scroll_top_button {
    background-color: #874daf;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 2;
}









/* For Responsive Screen */
@media only screen and (min-width: 0px) and (max-width: 431px) {
    .Doctorhome_website .docto_hometop {
        width: 100%;
        padding: 15px 10px 15px 10px;
    }

    .Doctorhome_website .docto_hometop .consult_onof {
        align-items: center;
    }

    .Doctorhome_website .docto_hometop .consult_onof h4 {
        margin-right: 3px;
        font-size: 14px;
    }


    /* Pending Appointment */
    .Doctorhome_website .allpendingappointm {
        width: 91%;
        margin-top: 8px;
    }

    .Doctorhome_website .allpendingappointm h3 {
        font-size: 17px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo {
        gap: 7px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo::-webkit-scrollbar {
        height: 1px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .pending_appoi {
        margin: 8px 13px 8px 13px;
        gap: 10px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .pending_appoi .skeltofpendngupcm {
        width: 35vh;
        height: 110px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .nopenrecorddiv {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .nopenrecorddiv h4 {
        color: black;
        align-self: center;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain {
        width: 55vh;
        margin: 5px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcomimge {
        width: 35px;
        height: 35px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde {
        margin-left: 5px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .poftext {
        font-size: 12px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .poftext .spanoftet {
        font-size: 8px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .clicoronlne {
        font-size: 8px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .cliconoffreshl {
        font-size: 11px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .feofupcom {
        margin-top: -8px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .datofupcome {
        font-size: 9px;
        margin-top: 18px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco {
        margin-top: 1px;
        margin-bottom: 2px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .timeicon {
        font-size: 12px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .timeoftextin {
        font-size: 9px;
        margin-left: 3px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .statusof {
        font-size: 8px;
        margin-left: 3px;
    }

    /* ................................. */


    /* Requested appointment */
    .Doctorhome_website .allappointforaccept .maindivallappo .request_appoi {
        margin: 10px 15px 10px 15px;
        gap: 10px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .request_appoi .skeltdochmapt {
        width: 35vh;
        height: 190px;
        border-radius: 8px;
    }

    .Doctorhome_website .allappointforaccept {

        margin: 10px 15px 0px 15px;
        gap: 10px;
    }

    .Doctorhome_website .allappointforaccept {
        margin-top: 12px;
    }

    .Doctorhome_website .allappointforaccept h3 {
        font-size: 17px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo::-webkit-scrollbar {
        height: 3px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman {
        width: 35vh;
        padding: 6px;
        margin: 5px 5px 0px 5px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .unameofacpt {
        font-size: 14px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .acatrgoryofacpt {
        font-size: 12px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .afeeofacpt {
        font-size: 11px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .cliconoffre {
        font-size: 13px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .reschilforacpt {
        font-size: 8px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .imaginaceptapoint {
        width: 52px;
        height: 52px;
        border-radius: 6px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .lininacpt {
        border-bottom: 1px solid #ddd;
        margin-top: 6px;
        margin-bottom: 3px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .calendrmothicn {
        font-size: 12px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .dattimixacpt {
        font-size: 10px;
        margin-left: 3px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .btnofacept {
        border-radius: 8px;
        padding: 6px;
        font-size: 13px;
        margin-top: 8px;
    }

    .Doctorhome_website .spaceindocorsumins {
        margin-top: 40px;
    }

    .Doctorhome_website .playstore_appsign {
        height: 200px;
        padding: 10px 10px 10px 10px;
    }

    .Doctorhome_website .playstore_appsign .image_playstore {
        width: 40%;
    }

    .Doctorhome_website .playstore_appsign .name_ty_logo .circule_for_logo {
        width: 20px;
        height: 20px;
    }

    .Doctorhome_website .playstore_appsign .name_ty_logo .circule_for_logo .imf_log {
        width: 13px;
        height: 13px;
    }

    .Doctorhome_website .playstore_appsign .name_ty_logo h4 {
        font-size: 13px;
    }


    .Doctorhome_website .playstore_appsign .twoo_three_divs h5 {
        font-size: 10px;
    }

    .Doctorhome_website .playstore_appsign .two_btn_goplay {
        gap: 5px;
    }

    .Doctorhome_website .playstore_appsign .two_btn_goplay .PlayStore {
        height: 35px;
        width: 110px;
        padding: 3px;
    }

    .Doctorhome_website .playstore_appsign .two_btn_goplay .PlayStore .playstore_img {
        width: 15px;
        height: 15px;
    }

    .Doctorhome_website .playstore_appsign .two_btn_goplay .PlayStore h4 {
        font-size: 13px;
    }

    /* ................................... */


    /* Footer Section */
    .footer_top {
        margin-top: 0px;
        padding: 0px;
    }

    .footer_second_end {
        height: 230px;
        padding: 0px 10px 0px 10px;
    }

    .footer_second_end .p_app_name {
        font-size: 18px;
    }

    .footer_second_end .text_light {
        font-size: 9px;
        margin-top: 8px;
    }

    .footer_second_end .footor_inside {
        width: 65%;
    }

    .footer_second_end .footor_inside .Departments {
        font-size: 10px;
        margin-bottom: 15px;
    }

    .footer_second_end .footor_inside ul li {
        padding: 3px 0px 3px 0px;
    }

    .footer_second_end .footor_inside ul li a {
        font-size: 9px;
    }

    .footer_end {
        padding: 25px;
    }

    .footer_end p {
        font-size: 11px;
    }

    .footer_end p a {
        font-size: 11px;
    }

    /* //////////////////////////////////// */


    .Doctorhome_website .scroll_top_button {
        padding: 10px 18px;
        bottom: 10px;
        right: 15px;
    }
}





/* Mobile styles */
@media only screen and (min-width: 431px) and (max-width: 680px) {
    .Doctorhome_website .docto_hometop {
        width: 100%;
        padding: 18px;
    }

    .Doctorhome_website .docto_hometop .consult_onof {
        align-items: center;
    }

    .Doctorhome_website .docto_hometop .consult_onof h4 {
        margin-right: 5px;
        font-size: 14px;
    }


    /* Pending Appointment */
    .Doctorhome_website .allpendingappointm {
        width: 91%;
        margin-top: 8px;
    }

    .Doctorhome_website .allpendingappointm h3 {
        font-size: 18px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo {
        gap: 8px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo::-webkit-scrollbar {
        height: 3px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .pending_appoi {
        margin: 8px 13px 8px 13px;
        gap: 10px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .pending_appoi .skeltofpendngupcm {
        width: 47vh;
        height: 110px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .nopenrecorddiv {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .nopenrecorddiv h4 {
        color: black;
        align-self: center;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain {
        width: 67vh;
        margin: 6px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcomimge {
        width: 38px;
        height: 38px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde {
        margin-left: 7px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .poftext {
        font-size: 13px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .poftext .spanoftet {
        font-size: 9px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .upcmnginsde .clicoronlne {
        font-size: 9px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .cliconoffreshl {
        font-size: 12px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .feofupcom {
        margin-top: -8px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .datofupcome {
        font-size: 10px;
        margin-top: 20px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco {
        margin-top: 1px;
        margin-bottom: 3px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .timeicon {
        font-size: 13px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .timeoftextin {
        font-size: 10px;
        margin-left: 3px;
    }

    .Doctorhome_website .allpendingappointm .maindivpendingappo .upcomngmain .mainoflastupco .statusof {
        font-size: 9px;
        margin-left: 3px;
    }

    /* ................................. */


    /* Requested appointment */
    .Doctorhome_website .allappointforaccept {
        margin-top: 15px;
    }

    .Doctorhome_website .allappointforaccept h3 {
        font-size: 18px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo::-webkit-scrollbar {
        height: 3px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman {
        width: 43vh;
        padding: 8px;
        margin: 5px 5px 0px 5px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .unameofacpt {
        font-size: 15px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .acatrgoryofacpt {
        font-size: 13px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .afeeofacpt {
        font-size: 12px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .cliconoffre {
        font-size: 14px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .reschilforacpt {
        font-size: 9px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .imaginaceptapoint {
        width: 55px;
        height: 55px;
        border-radius: 6px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .lininacpt {
        border-bottom: 1px solid #ddd;
        margin-top: 8px;
        margin-bottom: 3px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .calendrmothicn {
        font-size: 13px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .dattimixacpt {
        font-size: 11px;
        margin-left: 3px;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .btnofacept {
        border-radius: 8px;
        padding: 8px;
        font-size: 14px;
        margin-top: 8px;
    }


    .Doctorhome_website .spaceindocorsumins {
        margin-top: 60px;
    }


    .Doctorhome_website .playstore_appsign {
        height: 230px;
        padding: 15px 40px 15px 40px;
    }

    .Doctorhome_website .playstore_appsign .image_playstore {
        width: 50%;
    }

    .Doctorhome_website .playstore_appsign .name_ty_logo .circule_for_logo {
        width: 30px;
        height: 30px;
    }

    .Doctorhome_website .playstore_appsign .name_ty_logo .circule_for_logo .imf_log {
        width: 20px;
        height: 20px;
    }

    .Doctorhome_website .playstore_appsign .name_ty_logo h4 {
        font-size: 14px;
    }


    .Doctorhome_website .playstore_appsign .twoo_three_divs h5 {
        font-size: 11px;
    }

    .Doctorhome_website .playstore_appsign .two_btn_goplay .PlayStore {
        height: 40px;
        width: 120px;
        padding: 4px;
    }

    .Doctorhome_website .playstore_appsign .two_btn_goplay .PlayStore .playstore_img {
        width: 17px;
        height: 17px;
    }

    .Doctorhome_website .playstore_appsign .two_btn_goplay .PlayStore h4 {
        font-size: 14px;
    }

    /* ................................... */


    /* Footer Section */
    .footer_top {
        margin-top: 0px;
        padding: 5px;
    }

    .footer_second_end {
        height: 280px;
        padding: 0px 20px 0px 20px;
    }

    .footer_second_end .p_app_name {
        font-size: 25px;
    }

    .footer_second_end .text_light {
        font-size: 13px;
        margin-top: 10px;
    }

    .footer_second_end .footor_inside {
        width: 60%;
    }

    .footer_second_end .footor_inside .Departments {
        font-size: 12px;
        margin-bottom: 20px;
    }

    .footer_second_end .footor_inside ul li {
        padding: 5px 0px 5px 0px;
    }

    .footer_second_end .footor_inside ul li a {
        font-size: 11px;
    }

    .footer_end {
        padding: 30px;
    }

    .footer_end p {
        font-size: 13px;
    }

    .footer_end p a {
        font-size: 13px;
    }

    /* //////////////////////////////////// */


    .Doctorhome_website .scroll_top_button {
        padding: 10px 20px;
        bottom: 10px;
        right: 20px;
    }
}




/* Tablet styles */
@media only screen and (min-width: 680px) and (max-width: 950px) {
    .Doctorhome_website .docto_hometop {
        width: 100%;
        padding: 20px 30px 20px 30px;
    }

    .Doctorhome_website .playstore_appsign {
        padding: 15px 60px 15px 60px;
    }

    .Doctorhome_website .playstore_appsign .image_playstore {
        width: 40%;
    }

    .Doctorhome_website .allappointforaccept .maindivallappo .foraceptman .reschilforacpt {
        font-size: 9px;
    }

    .Doctorhome_website .spaceindocorsumins {
        margin-top: 70px;
    }

    /* Footer Section */
    .footer_top {
        margin-top: 5px;
        padding: 10px;
    }

    .footer_second_end {
        height: 350px;
        padding: 0px 80px 0px 45px;
    }

    .footer_second_end .p_app_name {
        font-size: 40px;
    }

    .footer_second_end .text_light {
        font-size: 15px;
        margin-top: 15px;
    }

    .footer_second_end .footor_inside {
        width: 55%;
    }

    .footer_second_end .footor_inside .Departments {
        font-size: 15px;
        margin-bottom: 25px;
    }

    .footer_second_end .footor_inside ul li {
        padding: 8px 0px 8px 0px;
    }

    .footer_second_end .footor_inside ul li a {
        font-size: 13px;
    }

    .footer_end {
        padding: 35px;
    }

    .footer_end p {
        font-size: 15px;
    }

    .footer_end p a {
        font-size: 15px;
    }

    /* //////////////////////////////////// */
}